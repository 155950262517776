import React from 'react'

import Button from '@mui/material/Button'
import { styled } from '@mui/material'

const ButtonNewBtn = styled(Button)(({ theme, size }) => ({
  display: 'inline-block',
  padding: '8px 16px',
  fontSize: size === 'smaller' ? '12px' : '16px',
  lineHeight: size === 'smaller' ? '8px' : '18px',
  border: 0,
  cursor: 'pointer',
  borderRadius: '8px',
  color: 'white',
  backgroundColor: '#000',
  fontWeight: 500,
  textAlign: 'center',
  transformStyle: 'preserve-3d',
  textTransform: 'none',

  //transition: 'background-color 300ms ease, transform 300ms ease, color 300ms ease, -webkit-transform 300ms ease',

  '&:hover': {
    backgroundColor: '#000',
    //transform: 'translate3d(0px, -3px, 0.01px)',
  },

  '& MuiButton-root': {
    textTransform: 'none',
  },
}))

const StyledStdBtn = (props) => {
  const { title, onClickHandler, disableCondition, size } = props

  return (
    <ButtonNewBtn
      style={{ ...props.style }}
      variant="contained"
      onClick={onClickHandler}
      disableFocusRipple
      disabled={disableCondition}
      size={size}
    >
      {title}
    </ButtonNewBtn>
  )
}

export default StyledStdBtn
