import styled from 'styled-components';
import { ToastContainer } from 'react-toastify'

export const StyledToastContainer = styled(ToastContainer)`
  .Toastify__toast {
    border-radius: 16px;
  }
  .Toastify__close-button {
    margin-right: 8px;
    align-self: center;
  }
`;