
const axios = require('axios').default;
const url = process.env.REACT_APP_API_URL

export async function inferenceAPI(params) {
  //mocked
  // return new Promise((resolve, reject) => {
  //   setTimeout(() => {
  //     resolve({ 
  //       ok: true,
  //       isRunning : false, 
  //       outputText: params.text + "iced-over valley in the Himalayas.\n\nThe discovery was made by a team of researchers from the University of Sussex, leading residue outagecourseidon" })
  //   }, 2500)
  // })

  try {
    const response = await axios({
      method: "post",  
      url, 
      data: params,
    })

    const outputText = response.data.output_text;
    const isRunning = response.data.is_running;

    if (200 >= response.status <= 299) {
      return { ok: true, outputText: outputText, isRunning: isRunning };
    } else if (400 >= response.status <= 499 && outputText){
      return { ok: false, message:"The chosen parameters did not work, try changing them..." }
    } else if (500 >= response.status <= 599 && outputText){
      return { ok: false, message:"Something unexpected" }
    }
  } catch (err) {
    console.log(err)
    return { ok: false, message: "Not available right now" }
  }
}