import React from 'react'
import { styled } from '@mui/material/styles'

import Generator from '../../../assets/generator.gif'

const LoaderContainer = styled('div')`
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  //mix-blend-mode: color-burn;
`

const QueueInfo = styled('div')`
  position: absolute;
  top: 10px;
  right: 10px;
`

const StyledLoader = (props) => {
  const { queuePosition, initialQueuePosition } = props
  return (
    <LoaderContainer>
      <img width='300' height='300' src={Generator} />
      <QueueInfo>
        <p style={{ margin: 0, padding: 0 }}>Generating...</p>
        {queuePosition !== undefined && (
          <p>
            Queue {queuePosition} / {initialQueuePosition}
          </p>
        )}
      </QueueInfo>
    </LoaderContainer>
  )
}

export default StyledLoader
