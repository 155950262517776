import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { showNotification } from '../utils/toast'
import { inferenceAPI } from '../utils/api'

export const MAX_RESPONSE_LENGTH = 2048

export const onMountRunningCheck = createAsyncThunk(
  'homepage/onMountRunningCheck',
  async (payload, thunkAPI) => {
    try {
      const state = await thunkAPI.getState().homepage
      const { isRunning } = await inferenceAPI({
        text: ' ',
        response_length: state.responseLength,
        temperature: state.temperature,
        repetition_penalty: state.repetitionPenalty,
        top_k: state.topK,
        top_p: state.topP,
      })
      thunkAPI.dispatch(setIsRunning(isRunning))
    } catch (err) {
      console.log(err)
    }
  }
)

export const inference = createAsyncThunk(
  'homepage/inference',
  async (text, thunkAPI) => {
    try {
      const state = await thunkAPI.getState().homepage
      const { ok, outputText, message, isRunning } = await inferenceAPI({
        text,
        response_length: state.responseLength,
        temperature: state.temperature,
        repetition_penalty: state.repetitionPenalty,
        top_k: state.topK,
        top_p: state.topP,
      })

      thunkAPI.dispatch(setIsRunning(isRunning))

      if (ok && isRunning) {
        return { outputText }
      } else if (ok && !isRunning) {
        // set timers if not already set
        if (!state.progressTimer || !state.pollingTimer) {
          const progressTimer = setInterval(() => {
            thunkAPI.dispatch(updateProgress(1))
          }, 2500)
          const pollingTimer = setInterval(() => {
            thunkAPI.dispatch(inference(state.text))
          }, 30000)
          thunkAPI.dispatch(saveProgressTimer(progressTimer))
          thunkAPI.dispatch(savePollingTimer(pollingTimer))
        }
        return thunkAPI.rejectWithValue({ isRunning })
      } else {
        return thunkAPI.rejectWithValue({ message })
      }
    } catch (err) {
      console.log(err)
      return thunkAPI.rejectWithValue({ message: 'Unexpected Error' })
    }
  }
)

export const homepageSlice = createSlice({
  name: 'homepage',
  initialState: {
    progressTimer: undefined,
    pollingTimer: undefined,
    loading: false,
    progress: 0,
    responseLength: 80,
    temperature: 0.5,
    repetitionPenalty: 1.3,
    topK: 40,
    topP: 1.0,
    text: '',
    isRunning: undefined,
  },
  reducers: {
    clearOutput(state) {
      state.text = ''
    },
    setText(state, action) {
      state.text = action.payload
    },
    setResponseLength(state, action) {
      state.responseLength = action.payload
    },
    setTemperature(state, action) {
      state.temperature = action.payload
    },
    setRepetitionPenalty(state, action) {
      state.repetitionPenalty = action.payload
    },
    setTopK(state, action) {
      state.topK = action.payload
    },
    setTopP(state, action) {
      state.topP = action.payload
    },
    setExample(state, action) {
      Object.assign(state, action.payload)
    },
    updateProgress(state, action) {
      if (state.progress < 90) state.progress += action.payload
    },
    setIsRunning(state, action) {
      state.isRunning = action.payload
    },
    savePollingTimer(state, action) {
      state.pollingTimer = action.payload
    },
    saveProgressTimer(state, action) {
      state.progressTimer = action.payload
    },
  },
  extraReducers: {
    [inference.pending]: (state, action) => {
      state.loading = true
    },
    [inference.fulfilled]: (state, action) => {
      state.loading = false
      state.progress = 0
      clearInterval(state.progressTimer)
      clearInterval(state.pollingTimer)
      state.text = action.payload.outputText
      showNotification('Request completed', 'success')
    },
    [inference.rejected]: (state, action) => {
      if (action.payload?.message) {
        state.loading = false
        showNotification(action.payload?.message)
      }
    },
  },
})

export const {
  savePollingTimer,
  saveProgressTimer,
  saveIntervals,
  setIsRunning,
  clearOutput,
  updateProgress,
  timeout,
  firstRequestDone,
  setExample,
  setText,
  setResponseLength,
  setRepetitionPenalty,
  setTemperature,
  setTopK,
  setTopP,
} = homepageSlice.actions

export const selectText = (state) => state.homepage.text
export const selectLoading = (state) => state.homepage.loading
export const selectResponseLength = (state) => state.homepage.responseLength
export const selectRepetitionPenalty = (state) =>
  state.homepage.repetitionPenalty
export const selectTemperature = (state) => state.homepage.temperature
export const selectTopK = (state) => state.homepage.topK
export const selectTopP = (state) => state.homepage.topP
export const selectFirstRequest = (state) => state.homepage.firstRequest
export const selectProgress = (state) => state.homepage.progress
export const selectIsRunning = (state) => state.homepage.isRunning

export default homepageSlice.reducer
