import React from 'react'

import "../assets/globals.css"


export const ConvertSection = () => {
  return (
    <div className='section cta wf-section'>
      <div className='container-default w-container'>
        <div className='w-layout-grid grid-2-columns cta'>
          <div
            data-w-id='809f5a50-e6b3-dbb7-fabe-a8a63dbb3508'
            className='inner-container _464px max-width-tablet-100'
          >
            <h2>Deploy it in your own cloud</h2>
            <p className='mg-bottom-32px'>
              Leveling the playing field of AI through easy-access to optimized
              AI computing
            </p>
            <div className='buttons-row'>
              <a
                data-w-id='4e9aea30-1433-082d-7c56-d5da279ea645'
                href="https://tally.so#tally-open=wLZYk2&tally-hide-title=1"
                className='btn-primary button-row w-button'
              >
                Get access
                <span className='line-rounded-icon link-icon-right'></span>
              </a>
            </div>
          </div>
          <div
            data-w-id='809f5a50-e6b3-dbb7-fabe-a8a63dbb3517'
            className='position-relative cta'
          >
            <div className='footer-subscribe-card cta-section'>
              <div className='mg-bottom-16px'>
                <div className='text-300 bold color-neutral-100 mg-bottom-16px'>
                  Subscribe now
                </div>
                <p className='color-neutral-400'>
                  Get latest updates in your inbox
                </p>
              </div>
              {/* <!-- mailer lite start--> */}
              <div 
              id="mlb2-1808309" 
              className="ml-form-embedContainer ml-subscribe-form ml-subscribe-form-1808309">
                <div className="ml-form-align-center ">
                  <div className="ml-form-embedWrapper embedForm">
                    <div className="ml-form-embedBody ml-form-embedBodyDefault row-form">
                      <form 
                        className="ml-block-form"
                        action="https://assets.mailerlite.com/jsonp/193227/forms/69231279496758644/subscribe"
                        data-code="" 
                        method="post" 
                        target="_blank"
                      >
                        <div className="ml-form-formContent">
                          <div className="ml-form-fieldRow ml-last-item">
                            <div className="ml-field-group ml-field-email ml-validate-email ml-validate-required">
                              <input 
                                aria-label="email" 
                                aria-required="true" 
                                type="email" 
                                className="form-control"
                                data-inputmask="" 
                                name="fields[email]" 
                                placeholder="Enter your email" 
                                autoComplete="email"/>
                            </div>
                          </div>
                        </div>
                        <input 
                          type="hidden" name="ml-submit" value="1"/>
                        <div className="ml-form-embedSubmit">
                          <button type="submit" className="primary">Subscribe</button>
                          <button disabled="disabled" style={{display: 'none'}} type="button" className="loading">
                            <div className="ml-form-embedSubmitLoad"></div>
                            <span className="sr-only">Loading...</span>
                          </button>
                        </div>
                        <input type="hidden" name="anticsrf" value="true"/>
                      </form>
                    </div>
                    <div className="ml-form-successBody row-success" style={{display: 'none'}}>
                      <div className="ml-form-successContent">
                        <h4>✅Thank you!</h4>
                        <p>You have successfully joined our subscriber list.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <!-- mailer lite end--> */}
              {/* <div className='w-form'>
                <form
                  id='wf-form-Footer-Subscribe'
                  name='wf-form-Footer-Subscribe'
                  data-name='Footer Subscribe'
                  action='https://stochastic.us14.list-manage.com/subscribe/post?u=59a55ebcd7b8a5bf017d129b1&amp;amp;id=5a45893c26'
                  method='post'
                >
                  <div className='w-layout-grid grid-subscribe-form-button-down'>
                    <input
                      type='email'
                      className='input input-subscribe-footer w-input'
                      maxLength='256'
                      name='EMAIL'
                      data-name='EMAIL'
                      placeholder='Enter your email'
                      id='EMAIL'
                      required=''
                    />
                    <input
                      type='submit'
                      value='Subscribe'
                      data-wait='Please wait...'
                      id='w-node-_4fcccbbb-03b6-25c3-eede-90eee61f9f38-3dbb3505'
                      className='btn-primary small white w-button'
                    />
                  </div>
                </form>
                <div className='success-message white-message w-form-done'>
                  <div className='flex'>
                    <div className='line-rounded-icon success-message-check'>
                      
                    </div>
                    <div className='color-neutral-800 text-left'>
                      Thank you for subscribing!
                    </div>
                  </div>
                </div>
                <div className='error-message w-form-fail'>
                  <div>
                    Oops! Something went wrong while submitting the form.
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}