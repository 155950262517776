import React, { useState } from 'react'
import Select from 'react-select'
import { styled } from '@mui/material/styles'
import { Typography, Button, Chip } from '@mui/material'
import BlurOnIcon from '@mui/icons-material/BlurOn'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'

//component
import StableDiffusion from './components/StableDiffusion'
import Gptj from './components/Gptjg'

const ContainerMain = styled('div')`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  margin: 0 auto;
  margin-top: 64px;
  padding: 1rem 10.5rem;
  background-color: #fff;
`

const AccessContainerMain = styled('div')`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px 32px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 11px 44px 0 rgb(18 18 19 / 10%);
  background-image: linear-gradient(
    59.94deg,
    rgba(144, 151, 247, 0.7),
    rgba(106, 154, 248, 0.8) 53.81%,
    rgba(70, 198, 253, 0.7) 100%,
    #64a2f9
  );
`

const SelectionContainer = styled('div')`
  width: 100%;
  display: flex;
  align-items: center;
  column-gap: 8px;
  padding: 16px 40px;
  margin-top: 24px;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 2px 12px 0 rgb(18 18 19 / 10%);
`

const PlayGroundContainer = styled('div')`
  width: 100%;
  height: 600px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  margin: 0 auto;
  margin-top: 16px;
  background-color: #fff;
`

const StyledActionButton = styled(Button)`
  padding: 8px 16px;
  font-size: 16px;
  font-family: Inter;
  font-weight: 500;
  line-height: 18px;
  border: 0;
  cursor: pointer;
  border-radius: 24px;
  color: #121213;
  background-color: #fff;
  text-align: center;
  transform-style: preserve-3d;
  text-transform: none;
  align-self: center;
  transition: background-color 300ms ease, transform 300ms ease,
    color 300ms ease, -webkit-transform 300ms ease;
  :hover {
    background-color: #fff;
    transform: translate3d(+3px, 0, 0.01px);
  }
  & .MuiButton-root {
    text-transform: none;
  }
`
const StyledSelect = styled(Select)`
  min-width: 200px;
`
const selectOptions = [
  { value: 'stable-diffusion', label: 'Stable Diffusion 2' },
  { value: 'GPT-J', label: 'GPT-J' },
  { value: 'BLOOM', label: 'Sign up for BLOOM' },
]

const playGroundMap = {
  'stable-diffusion': <StableDiffusion />,
  'GPT-J': <Gptj />,
}

const StableDiffusionPg = () => {
  const isRunning = true

  // component state
  const [selectModel, setSelectModel] = useState(selectOptions[0])

  return (
    <ContainerMain>
      <AccessContainerMain>
        <Typography flex={1} variant='h7' fontWeight={500} color='#fff'>
          Production-ready inference, fine-tuning
          <Typography
            variant='h7'
            fontWeight={300}
            color='rgba(255, 255, 255, 0.75)'
          >
            {' '}
            and{' '}
          </Typography>
          deployment.
        </Typography>
        <StyledActionButton
          variant='contained'
          endIcon={<ArrowForwardIcon />}
          href='https://app.stochastic.ai/signup'
        >
          Sign up
        </StyledActionButton>
      </AccessContainerMain>
      <SelectionContainer>
        <StyledSelect
          value={selectModel}
          isDisabled={false}
          isLoading={false}
          isClearable={false}
          isRtl={false}
          isSearchable={false}
          options={selectOptions}
          onChange={(value) => {
            if (value.value === 'BLOOM') {
              window.open('https://app.stochastic.ai/signup')
              return
            }
            setSelectModel(value)
          }}
        />
        <Chip
          sx={{
            flex: '10 1 0',
            justifySelf: 'flex-end',
            maxWidth: '150px',
            border: '0',
            margin: '0 10px',
          }}
          color={isRunning ? 'success' : 'error'}
          icon={<BlurOnIcon />}
          label={isRunning ? 'Connected' : 'Not connected'}
          variant='outlined'
        />
      </SelectionContainer>
      {/* Based on model here we show the playground */}
      <PlayGroundContainer>
        {playGroundMap[selectModel.value]}
      </PlayGroundContainer>
    </ContainerMain>
  )
}

export default StableDiffusionPg
