import React from 'react'
import Modal from '@mui/material/Modal'
import CloseButton from './CloseButton'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/material/styles';
import { Box } from '@mui/system';

const ModalBody = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: ${props => props.maxWidth ? props.maxWidth : ""};
  height: ${props => props.modalHeight ? props.modalHeight : ""};
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding: 32px;
  border-radius: 16px;
` 
const TitleContainer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`
const ModalTitle = styled(Typography)`
  font-weight: 600;
  margin-right: 30px;
`
const ModalContent = styled(Box)`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
  height: 100%;
  width: 100%;
  padding: 24px 16px;
`

const StyledModal = (props) => {
  const { isOpen, title, closeHandler, children, modalHeight, maxWidth } =
    props

  return (
    <Modal disableEnforceFocus disableAutoFocus open={isOpen}>
      <ModalBody modalHeight={modalHeight} maxWidth={maxWidth}>
        <TitleContainer>
          <ModalTitle  variant='h6'>{title}</ModalTitle>
          <CloseButton onClickHandler={() => closeHandler()}/>
        </TitleContainer>
        <ModalContent>
          {children}
        </ModalContent>
      </ModalBody>
    </Modal>
  )
}

export default StyledModal