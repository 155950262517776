import "../assets/globals.css"

export const Navbar = () => {
  return (
    <div
      data-w-id='c2ac369a-bb01-2411-3913-ecdf6c5877a2'
      data-animation='default'
      data-collapse='medium'
      data-duration='400'
      data-easing='ease'
      data-easing2='ease'
      role='banner'
      className='header-wrapper w-nav'
    >
      <div
        data-w-id='c2ac369a-bb01-2411-3913-ecdf6c5877a3'
        className='container-default w-container'
      >
        <div className='header-content-wrapper'>
            <a
              href='https://stochastic.ai/'
              aria-current='page'
              className='header-logo-link w-nav-brand w--current'
            >
              <img
                src='imgs/627c012087cd4f3351e85e72_stochastic_color_logo.svg'
                loading='eager'
                alt=''
                className='header-logo'
              />
            </a>
          <div className='header-middle'>
            <nav
              role='navigation'
              className='header-nav-menu-wrapper w-nav-menu'
            >
              <ul role='list' className='header-nav-menu-list'>
                <li className='header-nav-list-item middle'>
                    <a
                      href='https://stochastic.ai/'
                      aria-current='page'
                      className='header-nav-link w-nav-link w--current'
                    >
                      Home
                    </a>
                </li>
                {/* <li className='header-nav-list-item middle'>
                  <a href='https://stochastic.ai/#platform' className='header-nav-link w-nav-link'>Platform</a>
                </li> */}
                <li className='header-nav-list-item middle'>
                  <a href='https://playground.stochastic.ai/' className='header-nav-link w-nav-link'>Playground</a>
                </li>
                <li className='header-nav-list-item middle'>
                  <a href='https://stochastic.ai/blog' className='header-nav-link w-nav-link'>
                      Blog
                  </a>
                </li>
                <li className='header-nav-list-item middle'>
                  <a href='https://stochastic.ai/about' className='header-nav-link w-nav-link'>
                      About
                  </a>
                </li>
                <li className='header-nav-list-item middle'>
                  <a href='https://stochastic.ai/contact' className='header-nav-link w-nav-link'>
                    Contact
                  </a>
                </li>
                <li className='header-nav-list-item show-in-tablet'>
                  <a href='https://tally.so#tally-open=w7MPAm&amp;tally-emoji-text=👋&amp;tally-emoji-animation=wave' className='btn-primary small w-button'>
                    Get early access
                    <span className='line-rounded-icon link-icon-right'>
                      
                    </span>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
          <div className='header-right-side'>
            <div className='hamburger-menu-wrapper w-nav-button'>
              <div className='hamburger-menu-bar top'></div>
              <div className='hamburger-menu-bar bottom'></div>
            </div>
            <a href='https://app.stochastic.ai/login' className='btn-primary small header-btn-hidde-on-mb w-button'>
              Login
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}
