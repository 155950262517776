import React from 'react'
import CloseIcon from '@mui/icons-material/Close';
import styled from 'styled-components';

const StyledCloseButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  height: 35px;
  width: 35px;
  border-radius: 4px;
  cursor: pointer;
  background-color: #f5f5f5;
  :hover {
    background-color: #cecece;
  }
`

const CloseButton = ({onClickHandler}) => {

  return (
    <StyledCloseButton>
      <CloseIcon onClick={onClickHandler} />
    </StyledCloseButton>
  )
}

export default CloseButton