export const generation = {
  text: 'In a shocking finding, scientists discovered a herd of unicorns living in a remote, previously unexplored valley, in the Andes Mountains. Even more surprising to the researchers was the fact that the unicorns spoke perfect English.',
  responseLength: 75,
  temperature: 0.65,
  repetitionPenalty: 1.6,
  topK: 30,
  topP: 1.0,
}
export const code = {
  text: "function errorHandler(err, req, res, next) {\n    if (typeof (err) === 'string') {\n        // custom application error\n        return res.status(400).json({ message: err });\n    }\n\n    if (err.name === 'ValidationError') {\n        // mongoose validation error\n        return res.status(400).json({ message: err.message });\n    }\n\n    if (err.name === 'UnauthorizedError') {\n        // jwt authentication error\n        return res.status(401).json({ message: 'Invalid Token' });\n    }\n\n    // default to 500 server error\n    return res.status(500).json({ message: err.message });\n}\n\n\"\"\"\nHere's what the above function is doing:\n1.",
  responseLength: 100,
  temperature: 0.01,
  repetitionPenalty: 1,
  topK: 40,
  topP: 1.0,
}
export const sql = {
  text: 'Create a SQL request to find all users who live in California and have over 1000 credits:',
  responseLength: 50,
  temperature: 0.3,
  repetitionPenalty: 1.1,
  topK: 40,
  topP: 1.0,
}
export const chat = {
  text: 'The following is a conversation with an AI assistant. The assistant is helpful, intelligent, and very friendly.\n\nHuman: Hello, who are you?\nAI: I am an AI powered by GPT-J. How can I help you today?\nHuman: What should I do after work?\nAI:',
  responseLength: 70,
  temperature: 0.5,
  repetitionPenalty: 1.4,
  topK: 40,
  topP: 1.0,
}
export const summarization = {
  text: "Summarize the following article.\n\nArticle: From a full infrared image of the distant universe to a dazzling image of the Carina Nebula, the world was not disappointed. Besides the awe-inspiring images, Earthlings got a sneak peek into the kind of science JWST will be conducting as it searches for habitable exoplanets in the universe. Indeed, astronomers revealed the most detailed measurements of an exoplanet's atmosphere outside of our solar system to date —  and it appears that there are indications of water, haze and clouds in the planet's atmosphere that weren't previously known. The observed exoplanet in question is named WASP-96b, and it is one of the more than 5,000 planets that have been confirmed to exist in the Milky Way galaxy outside of our own solar system to date. Cataloguing and discovering exoplanets, meaning planets in other solar systems, is an impressive human achievement: because they are so much smaller than stars, and thus so much fainter, they are far harder to see. In fact, the first exoplanet wasn't detected until 1992 — meaning that any science fiction produced prior to then that involved explorers visiting other planets was based on speculation.\n\nSummarized article:",
  responseLength: 63,
  temperature: 0.3,
  repetitionPenalty: 1.0,
  topK: 40,
  topP: 1.0,
}
export const translation = {
  text: 'English: I do not speak French.\nFrench: je ne parle pas français\n\nEnglish: How are you?\nFrench: Comment vas-tu?\n\nEnglish: Where is the bathroom?\nFrench: Où se trouvent les toilettes?\n\nEnglish: When is your birthday?\nFrench: Quel est ton anniversaire?\n\nEnglish: What time is breakfast?\nFrench:',
  responseLength: 65,
  temperature: 0.5,
  repetitionPenalty: 1.0,
  topK: 40,
  topP: 1.0,
}
export const classification = {
  text: 'The following is a list of food and the countries they originate from.\n\nSpaghetti: Italy\nHamburger: USA\nWiener Schnitzel: Austria\nMeat Pie: Australia\nLasagna: Italy\nFish & chips:',
  responseLength: 10,
  temperature: 0.01,
  repetitionPenalty: 1.0,
  topK: 40,
  topP: 1.0,
}
export const blogIdea = {
  text: "Your task is to write a blog idea based on a company's name and product description.\n\nCompany Name: Zevo Insect\n\nProduct Description: Nobody likes having insects around the house, and the old fly swatter doesn’t really make much of difference with these persistent pests. Zevo is a plug and decimate solution. The multi-spectrum light technology attracts the critters and traps them on a sticky film backing. No chemicals. No gimmicks. And no need to go anywhere near the insects.\n\nBlog Idea: How to Make Fruit Flies Disappear - A blog all about the Zevo and how it can make your life better.\n\n###\n\nCompany Name: Ernest Alexander\n\nProduct Description: A great bag can take you anywhere. It is a faithful companion on great adventures, constantly there to hold your wallet and a bottle of water. Ernest Alexander embodies the idea that our clothes and personal items are a reflection of who we are, so they use premium materials to create high quality bags that last.\n\nBlog Idea:",
  responseLength: 70,
  temperature: 0.9,
  repetitionPenalty: 1.0,
  topK: 40,
  topP: 1.0,
}
export const extractText = {
  text: 'Your task is to extract airport codes from the Text.\n\nText: "I want to fly form Los Angeles to Miami."\nAirport codes: LAX, MIA\n\nText: "I want to fly from Dallas to San Francisco"\nAirport codes:',
  responseLength: 5,
  temperature: 0.0,
  repetitionPenalty: 1.0,
  topK: 40,
  topP: 1.0,
}
export const sentiment = {
  text: 'This is a tweet sentiment classifier\n\nTweet: "I loved the new Batman movie!"\nSentiment: Positive\n###\nTweet: "I hate it when my phone battery dies."\nSentiment: Negative\n###\nTweet: "My day has been 👍"\nSentiment: Positive\n###\nTweet: "This is the link to the article"\nSentiment: Neutral\n###\nTweet: "This new music video blew my mind"\nSentiment:',
  responseLength: 70,
  temperature: 0.2,
  repetitionPenalty: 1.0,
  topK: 40,
  topP: 1.0,
}

export const descRepetitionPenalty =
  'Repetition penalty works by lowering the chances of a word being selected again the more times that word has already been used. In other words, it works to prevent repetitive word usage.'
export const descResponseLength =
  'Response length is the length of the generated text, in tokens, you’d like based on your prompt. A token is roughly 4 characters including alphanumerics and special characters.'
export const descTemperature =
  'Temperature controls the randomness of the generated text. A value of 0 makes the engine deterministic, which means that it will always generate the same output for a given input text. A value of 1 makes the engine take the most risks and use a lot of creativity. As a frame of reference, it is common for story completion or idea generation to see temperature values between 0.7 to 0.9.'
export const descTopK =
  "Top-K sampling means sorting by probability and zero-ing out the probabilities for anything below the k'th token. A lower value improves quality by removing the tail and making it less likely to go off topic."
export const descTopP =
  'Top-P is an alternative way of controlling the randomness and creativity of the generated text. We recommend that only one of Temperature and Top P are used, so when using one of them, make sure that the other is set to 1. A rough rule of thumb is that Top-P provides better control for applications in which GPT-J is expected to generate text with accuracy and correctness, while Temperature works best for those applications in which original, creative or even amusing responses are sought.'
