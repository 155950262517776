import React, { useState, useEffect } from 'react'
import { styled } from '@mui/material/styles'

//components
import SliderItem from '../../../components/SliderItem'
import StyledStdBtn from '../../../components/StyledStdBtn/StyledStdBtn'

//service
import modelApiService from '../../../services/modelApi.service'
import { showNotification } from '../../../utils/toast'
import StyledLoader from './StyledLoader'

const PaneContainerMain = styled('div')`
  width: 100%;
  height: 400px;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  column-gap: 16px;
`
const PaneContainerLeft = styled('div')`
  width: 70%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 16px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 11px 44px 0 rgb(18 18 19 / 10%);
`
const PaneContainerRight = styled('div')`
  width: 30%;
  height: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  background-color: #fff;
  padding: 16px;
  box-shadow: 0 11px 44px 0 rgb(18 18 19 / 10%);
`
const PlayGround = styled('div')`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  row-gap: 16px;
  margin-top: 8px;
  padding: 24px;
  border-radius: 10px;
`
const PlayGroundItem = styled('div')`
  display: flex;
`
const PlayGroundController = styled('div')`
  display: flex;
  height: 100%;
  padding: 16px;
  border: 1px solid grey;
  border-radius: 8px;
  background-color: ${(props) => props.theme.palette.background.paper};
`
const PlayGroundImage = styled('div')`
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
`
const StableDiffusion = () => {
  //component states
  //Inference params and prompt
  const [inputPrompt, setInputPrompt] = useState('')
  const [seed, setSeed] = useState(0)
  const [guidanceScale, setGuidanceScale] = useState(7.5)
  const [numInferenceSteps, setNumInferenceSteps] = useState(50)

  //Queue related states
  const [responseUrl, setResponseUrl] = useState(undefined)
  const [initialQueuePosition, setInitialQueuePosition] = useState(undefined)
  const [queuePosition, setQueuePosition] = useState(undefined)

  const [imageSource, setImageSource] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    let pollingTimerId
    let count = 0

    const getImage = async () => {
      try {
        const response1 = await modelApiService.getInference(responseUrl)
        console.log('COUNT', count++)
        setQueuePosition((prev) => {
          if (prev && prev > 0) {
            return prev - 1
          }
          return prev
        })
        if (
          response1.data.success &&
          Array.isArray(response1.data.data.generatedImages)
        ) {
          const source = response1.data.data.generatedImages[0]?.presigned_url
          setImageSource(source)
          clearInterval(pollingTimerId)
          setQueuePosition(undefined)
          showNotification('Image generated successfully', 'success')
          setIsLoading(false)
        }
      } catch (error) {
        console.log('ERROR While polling:', error.message)
        clearInterval(pollingTimerId)
        setQueuePosition(undefined)
        showNotification('Something went wrong please try again', 'error')
        setIsLoading(false)
      }
    }

    if (queuePosition !== undefined) {
      pollingTimerId = setInterval(() => {
        getImage()
      }, 3000)
    }

    return () => {
      clearInterval(pollingTimerId)
    }
  }, [queuePosition])

  const handleSubmit = async () => {
    if (inputPrompt === '') {
      showNotification('Please enter some prompt', 'error')
      return
    }
    const reqBody = {
      prompt: inputPrompt,
      img_height: 512,
      img_width: 512,
      num_inference_steps: numInferenceSteps,
      guidance_scale: guidanceScale,
      seed: seed,
    }

    try {
      setIsLoading(true)
      const response = await modelApiService.getInferenceData(
        `stable-diffusion-2`,
        reqBody
      )

      if (response.status === 200) {
        //console.log('response', response.data.data)
        setResponseUrl(response.data.data.responseUrl)
        setInitialQueuePosition(Number(response.data.data.queuePosition) + 1)
        setQueuePosition(Number(response.data.data.queuePosition) + 1)
      } else if (response.status === 429) {
        setIsLoading(false)
        showNotification('Quota exceeded, please try again after 5 min')
      } else {
        setIsLoading(false)
        showNotification('Something went wrong please try again.')
      }
    } catch (error) {
      console.log('ERROR:', error.message)
    }
  }

  const onKeyDown = (e) => {
    const { key } = e
    if (key === 'Enter') {
      e.preventDefault()
      handleSubmit()
    }
  }

  return (
    <PaneContainerMain>
      <PaneContainerLeft>
        <PlayGround>
          <PlayGroundItem>
            <input
              onKeyDown={onKeyDown}
              autoFocus
              placeholder='Enter your prompt here. Eg: A cat driving a car'
              value={inputPrompt}
              onChange={(e) => setInputPrompt(e.target.value)}
              style={{
                width: '70%',
                padding: '12px',
                borderRadius: '8px 0px 0px 8px',
              }}
            />
            <StyledStdBtn
              style={{
                width: 'calc(30% + 16px)',
                borderRadius: '0 8px 8px 0',
              }}
              title='Generate'
              disableCondition={inputPrompt === '' || isLoading}
              onClickHandler={handleSubmit}
            />
          </PlayGroundItem>
          <PlayGroundController>
            <PlayGroundImage>
              {isLoading && (
                <StyledLoader
                  queuePosition={queuePosition}
                  initialQueuePosition={initialQueuePosition}
                />
              )}
              {imageSource !== '' && !isLoading && (
                <img width='400' height='400' src={imageSource} />
              )}
            </PlayGroundImage>
          </PlayGroundController>
        </PlayGround>
      </PaneContainerLeft>
      <PaneContainerRight>
        <SliderItem
          label='Inference steps'
          defaultValue={numInferenceSteps}
          min={0}
          max={50}
          step={1}
          description={'Number of denoising steps (minimum: 1; maximum: 50)'}
          onChange={(newValue) => setNumInferenceSteps(newValue)}
        />
        <SliderItem
          label='Guidance scale'
          defaultValue={guidanceScale}
          min={0}
          max={20}
          step={0.1}
          description={
            'Scale for classifier-free guidance (minimum: 1; maximum: 20)'
          }
          onChange={(newValue) => setGuidanceScale(newValue)}
        />
        <SliderItem
          label='Seed'
          defaultValue={seed}
          min={0}
          max={2147483647}
          step={1}
          description={'Random seed. Leave blank to randomize the seed'}
          onChange={(newValue) => setSeed(newValue)}
        />
      </PaneContainerRight>
    </PaneContainerMain>
  )
}

export default StableDiffusion
