import React from 'react'
import { Provider } from 'react-redux'
import useMediaQuery from '@mui/material/useMediaQuery'

//components
import { Root } from './components/Root'
import { Footer } from './components/Footer'
import { Navbar } from './components/Navbar'
import { ConvertSection } from './components/ConvertSection'
import { StyledToastContainer } from './components/StyledToastContainer'
import { FeatureList } from './components/FeatureList'
import { MobileInfo } from './components/MobileInfo'

//pages
import Playground from './pages/homepage/Playground'

//store
import { store } from './redux/store'

//styles and assets
import 'react-toastify/dist/ReactToastify.css'

export default () => {
  const matches = useMediaQuery('(min-width:600px)')

  return (
    <Provider store={store}>
      <Root>
        <Navbar />
        {matches ? <Playground /> : <MobileInfo />}
        <FeatureList />
        <ConvertSection />
        <Footer />
        <StyledToastContainer />
      </Root>
    </Provider>
  )
}