import "../assets/globals.css"

export const Footer = () => {
  return (
    <footer className='footer-wrapper'>
      <div className='container-default w-container'>
        <div className='footer-top'>
          <div className='w-layout-grid grid-footer'>
            <div
              id='w-node-_555684ba-e4a1-9bcd-7e57-7429dc0b6976-dc0b6971'
              data-w-id='555684ba-e4a1-9bcd-7e57-7429dc0b6976'
            >
              <a
                href='https://stochastic.ai/'
                aria-current='page'
                className='footer-logo-wrapper w-inline-block w--current'
              >
                <img
                  src='imgs/627c01200a26c2231da26387_stochastic_light_logo.svg'
                  alt=''
                  className='footer-logo'
                />
              </a>
              <div className='text-300 bold color-neutral-100 mg-bottom-10px'>
                Stochastic
              </div>
              <p className='color-neutral-400'>
                Harvard Innovation Labs
                <br />
                125 Western Ave
                <br />
                Boston, MA 02163
                <br />
              </p>
            </div>
            <div
              data-w-id='555684ba-e4a1-9bcd-7e57-7429dc0b698c'
              className='footer-column'
            >
              <div className='text-300 bold footer-title'>Pages</div>
              <div className='footer-menu-content _2-columns'>
                <ul role='list' className='footer-list-wrapper left'>
                  <li className='footer-list-item'>
                    <a
                      href='https://stochastic.ai/'
                      aria-current='page'
                      className='footer-link w--current'
                    >
                      Home
                    </a>
                  </li>
                  {/* <li className='footer-list-item'>
                    <a href='https://stochastic.ai/#platform' className='footer-link'>
                      Platform
                    </a>
                  </li> */}
                  <li className='footer-list-item'>
                    <a href='https://playground.stochastic.ai' className='footer-link'>
                      Playground
                    </a>
                  </li>
                  <li className='footer-list-item'>
                    <a href='https://stochastic.ai/about' className='footer-link'>
                      About
                    </a>
                  </li>
                  <li className='footer-list-item'>
                    <a href='https://stochastic.ai/blog' className='footer-link'>
                      Blog
                    </a>
                  </li>
                </ul>
                <ul role='list' className='footer-list-wrapper right'>
                  <li className='footer-list-item'>
                    <a href='https://stochastic.ai/careers' className='footer-link'>
                      Careers
                    </a>
                  </li>
                  <li className='footer-list-item'>
                    <a href='https://stochastic.ai/terms-of-service' className='footer-link'>
                      Terms of Service
                    </a>
                  </li>
                  <li className='footer-list-item'>
                    <a href='https://stochastic.ai/privacy-policy' className='footer-link'>
                      Privacy Policy
                    </a>
                  </li>
                  <li className='footer-list-item'>
                    <a href='https://stochastic.ai/contact' className='footer-link'>
                      Contact
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div data-w-id='555684ba-e4a1-9bcd-7e57-7429dc0b69c9'>
              <div className='text-300 bold footer-title'>Follow us</div>
              <ul role='list' className='footer-list-wrapper'>
                <li className='footer-list-item'>
                  <a
                    href='https://discord.gg/TgHXuSJEk6'
                    data-w-id='555684ba-e4a1-9bcd-7e57-7429dc0b69ce'
                    target='_blank'
                    className='social-media-link-wrapper w-inline-block'
                  >
                    <div className='social-icon-square footer-icon'>
                      <div className='social-icon-font'></div>
                    </div>
                    <div className='social-media-link-text'>Discord</div>
                  </a>
                </li>
                <li className='footer-list-item'>
                  <a
                    href='https://twitter.com/stochasticai'
                    data-w-id='555684ba-e4a1-9bcd-7e57-7429dc0b69d5'
                    target='_blank'
                    className='social-media-link-wrapper w-inline-block'
                  >
                    <div className='social-icon-square footer-icon'>
                      <div className='social-icon-font'></div>
                    </div>
                    <div className='social-media-link-text'>Twitter</div>
                  </a>
                </li>
                <li className='footer-list-item'>
                  <a
                    href='https://www.linkedin.com/company/stochasticai/'
                    data-w-id='555684ba-e4a1-9bcd-7e57-7429dc0b69e3'
                    target='_blank'
                    className='social-media-link-wrapper w-inline-block'
                  >
                    <div className='social-icon-square footer-icon'>
                      <div className='social-icon-font'></div>
                    </div>
                    <div className='social-media-link-text'>LinkedIn</div>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div
          data-w-id='555684ba-e4a1-9bcd-7e57-7429dc0b69f0'
          className='footer-bottom'
        >
          <p className='mg-bottom-0'>
            Copyright © Stochastic Inc 2022
            <a
              href='https://stochastic.ai/'
              target='_blank'
              className='link white'
            ></a>
          </p>
        </div>
      </div>
    </footer>
  )
}
