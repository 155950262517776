import axios from './axios'

const modelApisService = {
  getInferenceData: async (model, data) => {
    try {
      const response = await axios.post(
        `https://api-dev.stochastic.ai/v1/modelApi/playground/submit/${model}`,
        data
      )
      return response
    } catch (err) {
      console.log('ERROR:', err.message)
      return err.response
    }
  },
  getInference: async (url) => {
    try {
      const response = await axios.get(url)
      return response
    } catch (err) {
      console.log('ERROR:', err.message)
      return err.response
    }
  },

  stableDiffusionInference: async (url, data) => {
    try {
      const response = await axios.post(url, data)
      return response
    } catch (err) {
      console.log('ERROR:', err.message)
      return err.response
    }
  },
  gptjInference: async (url, data) => {
    try {
      const response = await axios.post(url, data)
      return response
    } catch (err) {
      console.log('ERROR:', err.message)
      return err.response
    }
  },
}

export default modelApisService
