import { Alert } from "@mui/material";
import React from "react";
import { styled as muiStyled } from '@mui/material/styles';

const AlertStyled = muiStyled(Alert)`
  margin: 10% 0;
`

export const MobileInfo = () => {

  return(
    <AlertStyled severity="warning">Playground is only available on Tablet and Desktop devices!</AlertStyled>
  )
}