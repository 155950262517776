import React from 'react';
import { Stack, Typography } from "@mui/material"

const Feature = ({title, desc}) =>{

  return(
    <Stack direction="column" width="300px" >
      <Typography noWrap={false} variant="h6">{title}</Typography>
      <Typography variant="body1">{desc}</Typography>
    </Stack>
  )
}

export const FeatureList = () =>{

  return(
    <Stack flexWrap="wrap" rowGap={5} direction="row" alignItems="flex-start" margin="10% 5% 2.5% 5%" justifyContent="space-evenly">
      <Feature title="🚀 Automatic Acceleration" desc="Get over 10x speedup by leveraging cutting-edge optimization of large deep learning models using software and hardware acceleration techniques" />
      <Feature title="📈 Auto-Scaling Deployments" desc="Deploy auto-scaling optimized models in the cloud with complete transparency of the underlying hardware" />
      <Feature title="⏳ Reduce time to market" desc="Reduce the time it takes to optimize the model and serving stack to production-grade performance from months to hours" />
      <Feature title="💰 Minimize cloud costs" desc="Minimize cloud costs, compute resources and carbon-footprint used for serving models by more than 80%" />
    </Stack>
  )
}