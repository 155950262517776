import React, { useEffect, useState } from 'react';

import styled from "styled-components";
import { styled as muiStyled } from '@mui/material/styles';

import Typography from '@mui/material/Typography';
import { IconButton, Slider } from '@mui/material';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0.4rem 0.7rem;
`

const Section = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`

const Label = muiStyled(Typography)`
  display: block;
  color: #121213;
  font-weight: 700;
  font-size: 12px;
  margin: 0.1rem;
  flex: ${props => props.flex ? props.flex : "0 1 auto"};
`;

const SliderItem = ({label, onChange, defaultValue, step, min, max, description}) =>{

  const [value, setValue] = useState(defaultValue)

  const handleChange = (event, newValue) => {
    setValue(newValue);

  };

  const handleChangeCommitted = () => onChange(value);

  useEffect(() =>{
    setValue(defaultValue)
  }, [defaultValue])

  return(
    <Container>
      <Section>
        <HtmlTooltip title={description}>
          <IconButton
            component="span"
            size="small">
            <InfoOutlinedIcon fontSize="inherit"/>              
          </IconButton>
        </HtmlTooltip>           
        <Label variant='h6'>{label + ":"}</Label>
        <Label variant='h6' flex="1" align="right">{value}</Label>
      </Section>
      <Slider
        key={'slider-'+label}
        valueLabelDisplay="off" 
        defaultValue={defaultValue}
        step={step}
        min={min}
        max={max}
        value={value}
        onChange={handleChange}
        onChangeCommitted={handleChangeCommitted}
        size="small"/>
    </Container>
  )
}

export default SliderItem

const HtmlTooltip = muiStyled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'rgb(245, 255, 255, 0.95)',
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: theme.typography.pxToRem(14),
    border: '1px solid #dadde9',
    lineHeight: "normal"
  },
}));