import React from 'react'

import { Stack, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'

import QuestionAnswerIcon from '@mui/icons-material/QuestionAnswer'
import CodeIcon from '@mui/icons-material/Code'
import TransformIcon from '@mui/icons-material/Transform'
import NotesIcon from '@mui/icons-material/Notes'
import ShortTextIcon from '@mui/icons-material/ShortText'
import TranslateIcon from '@mui/icons-material/Translate'
import CategoryIcon from '@mui/icons-material/Category'
import AutoStoriesIcon from '@mui/icons-material/AutoStories'
import MoveDownIcon from '@mui/icons-material/MoveDown'
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt'

import StyledModal from '../../../components/StyledModal'

import {
  generation,
  sql,
  code,
  chat,
  summarization,
  translation,
  classification,
  blogIdea,
  extractText,
  sentiment,
} from '../config/inferenceExample.config'

const StyledStack = styled(Stack)`
  flex-direction: row;
  align-content: flex-start;
  align-items: center;
  border: 2px solid #dadde9;
  padding: 10px;
  margin: 10px 0px;
  :hover {
    cursor: pointer;
    border: 2px solid #6c6c73;
  }
  flex: 0 1 250px;
`
const Example = ({ Icon, title, desc, preset, closeHandler, setExample }) => {
  return (
    <StyledStack
      onClick={() => {
        closeHandler()
        setExample(preset)
      }}
    >
      {Icon}
      <Stack direction='column' marginLeft='10px'>
        <Typography sx={{ fontWeight: '500' }}>{title}</Typography>
        <Typography sx={{ fontSize: '15px' }}>{desc}</Typography>
      </Stack>
    </StyledStack>
  )
}

export const ExamplesModal = ({ isOpen, closeHandler, setExample }) => {
  return (
    <StyledModal
      maxWidth='70%'
      title='Select one example'
      isOpen={isOpen}
      closeHandler={() => closeHandler()}
    >
      <Example
        preset={generation}
        Icon={<NotesIcon />}
        title='Generation'
        desc='Start a text and let the model continue'
        setExample={setExample}
        closeHandler={closeHandler}
      />
      <Example
        preset={code}
        Icon={<CodeIcon />}
        title='Explain code'
        desc='Explain code in natural language'
        setExample={setExample}
        closeHandler={closeHandler}
      />
      <Example
        preset={sql}
        Icon={<TransformIcon />}
        title='SQL translation'
        desc='Translate natural language to SQL queries'
        setExample={setExample}
        closeHandler={closeHandler}
      />
      <Example
        preset={chat}
        Icon={<QuestionAnswerIcon />}
        title='Chat'
        desc='Open ended conversation with an AI'
        setExample={setExample}
        closeHandler={closeHandler}
      />
      <Example
        preset={summarization}
        Icon={<ShortTextIcon />}
        title='Summarization'
        desc='Summarize text from any passage'
        setExample={setExample}
        closeHandler={closeHandler}
      />
      <Example
        preset={translation}
        Icon={<TranslateIcon />}
        title='Translation'
        desc='Translate English to French'
        setExample={setExample}
        closeHandler={closeHandler}
      />
      <Example
        preset={classification}
        Icon={<CategoryIcon />}
        title='Classification'
        desc='Classify items into categories'
        setExample={setExample}
        closeHandler={closeHandler}
      />
      <Example
        preset={blogIdea}
        Icon={<AutoStoriesIcon />}
        title='Blog idea'
        desc='Turn a description into a blog idea'
        setExample={setExample}
        closeHandler={closeHandler}
      />
      <Example
        preset={extractText}
        Icon={<MoveDownIcon />}
        title='Text extraction'
        desc='Extract codes from text'
        setExample={setExample}
        closeHandler={closeHandler}
      />
      <Example
        preset={sentiment}
        Icon={<SentimentSatisfiedAltIcon />}
        title='Sentiment'
        desc='Detect sentiment in tweets'
        setExample={setExample}
        closeHandler={closeHandler}
      />
    </StyledModal>
  )
}
